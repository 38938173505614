<template>
<div class="p-5">
  <Loading :isLoading="isLoading" />
  <div>
    <p style="padding:14px 14px 0px 0px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Inicio</p>
  </div>
  <div class="md:grid md:grid-cols-3 md:grid-flow-col grid grid-cols-1 gap-10">
    <div class="md:row-span-3 md:col-span-2" style="overflow-x:auto;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px;">
      <div class="grid grid-cols-2" style="padding:16px 16px 0 10px;">
        <div>
          <router-link to="/supervisor/cuenta/solicitudes-ingreso" style="font-size:15px;color:#810042;font-family:lato;font-weight:700;"> Solicitudes Nuevas ({{newRequestsCount}})</router-link>
        </div>
        <div>
          <img src="@/assets/Analyst/setting.svg" alt="" style="float:right;">
        </div>
      </div>
      
      <TableComponent :key="render.news" :columns="columns.news" :rows="rows.news" :withStyle="false" />
    </div>

      <ListCard 
        title="Mis tareas"
        :pendingRequestsCount="pendingRequestsCount"
        :listTasksIsLoading="listTasksIsLoading" 
        :listRequests="[
          { 
            name: 'Solicitudes en proceso',
            value: pendingRequestsCount,
            url: '/supervisor/solicitudes-en-proceso',
          },
          {
            name: 'Solicitudes Aceptadas',
            value: aceptedRequestsCount,
            url: '/supervisor/cuenta/solicitudes-aceptadas',
          },
          {
            name: 'Solicitudes Rechazadas',
            value: rejectedRequestsCount,
            url: '/supervisor/cuenta/solicitudes-rechazadas',
          },
          {
            name: 'Solicitudes Asignadas',
            value: assignedRequestsCount,
            url: '/supervisor/cuenta/solicitudes-asignadas',
          }
          ]"
        />
        <ListCard 
        classContainer="row-span-1 col-span-1"
        title="Solicitudes Incompletas"
        :pendingRequestsCount="pendingRequestsCount"
        :listTasksIsLoading="listTasksIsLoading" 
        :listRequests="[
          { 
            name: 'Solicitudes Incompletas',
            value: incompleteRequestCount,
            url: '/supervisor/solicitudes-incompletas',
          },
          ]"
        />
  </div>
 
  <div class="md:grid md:grid-cols-3 grid grid-cols-1 gap-10 mt-10">  
    <div class="md:row-span-3 md:col-span-2" style="overflow-x:auto;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px;">
     
      <div class="grid grid-cols-3" style="padding:16px 16px 0 10px;">
        <div class="col-span-2">
          <p style="color:#174A84;font-weight:bold;font-family:Lato;font-weight:700;"><router-link to="/supervisor/pago-parte-social" style="font-size:15px;color:#810042;font-family:lato;font-weight:700;">Solicitudes con status "pendiente de revisión", "en revisión" y con recibo de pago a inscripción de la fecha {{ $filters.formatDate(rangeOfYearRequestWithPayment[0]) }} a la fecha {{ $filters.formatDate(rangeOfYearRequestWithPayment[1]) }}</router-link></p>
        </div>
        <div class="col-span-1">
          <img src="@/assets/Analyst/setting.svg" alt="setting" style="float:right;">
        </div>
      </div>
      <TableComponent :key="render.paid" :columns="columns.paid" :rows="rows.paid" :withStyle="false" />
    </div>

    <!--Solicitudes con pago de inscripción-->
    <ListCard 
        :title="'Solicitudes con Pago de Inscripción(' + (requestWithRegistrationFee.news + requestWithRegistrationFee.unsigned + requestWithRegistrationFee.signed) +')'"
        :pendingRequestsCount="pendingRequestsCount"
        :listTasksIsLoading="listTasksIsLoading" 
        :listRequests="[
          { 
            name: 'Nuevas',
            value: requestWithRegistrationFee.news,
            url: '/supervisor/pagos/inscripcion',
          },
          { 
            name: 'Pendientes de Firma',
            value: requestWithRegistrationFee.unsigned,
            url: '/supervisor/pagos/inscripcion/categoria/no-firmados',
          },
          { 
            name: 'Firmadas',
            value: requestWithRegistrationFee.signed,
            url: '/supervisor/pagos/inscripcion/firmados',
          },
          ]"
        />

    <!--Solicitudes con pago de aportación-->

    <ListCard 
        class="row-span-1 col-span-1"
        :title="'Solicitudes con Pago de Aportación (' + (requestWithRegistrationPaymentSigned.news + requestWithRegistrationPaymentSigned.unsigned +  requestWithRegistrationPaymentSigned.signed) + ')'"
        :pendingRequestsCount="pendingRequestsCount"
        :listTasksIsLoading="listTasksIsLoading" 
        :listRequests="[
          { 
            name: 'Nuevas',
            value: requestWithRegistrationPaymentSigned.news,
            url: '/supervisor/pagos/aportacion/categoria/nuevos',
          },
          { 
            name: 'Pendientes de Firma',
            value: requestWithRegistrationPaymentSigned.unsigned,
            url: '/supervisor/pagos/aportacion/categoria/no-firmados',
          },
          { 
            name: 'Firmadas',
            value: requestWithRegistrationPaymentSigned.signed,
            url: '/supervisor/pagos/aportacion/firmados',
          },
          ]"
        />
  </div>
</div>
</template>

<script>
import { onMounted, onBeforeMount, ref, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

import Loading from '@/components/Loading/VueLoading.vue';
import {getRangeOfDatesByYears} from '@/helpers/dates';
import '@/assets/css/tailwind.css';
import {getTokenDecoden} from "@/helpers/tokenauth";

import TableComponent from "@/components/Forms/Tables/TableComponent";
import ListCard from "@/components/Forms/Cards/ListCard";
import AffiliatedRequest from "@/classes/Affiliated";
import AdmissionRequest from "@/classes/AdmissionRequest";

export default {
  name: 'PaginaPrincipal',

  setup(){
    const apiToken = ref("");
    const personId = ref("");
    const userId = ref("");
    const isLoading = ref(false);
    const allNewRequests = ref([]);
    const newRequests = ref([]);
    const requestWithRegistrationPayment = ref([]);
    const allPendingRequests = ref([]);
    const pendingRequests = ref([]);
    const aceptedRequests = ref([]);
    const assignedRequests = ref([]);
    const requests = ref([]);
    const newRequestsCount = ref("-");
    const listTasksIsLoading = ref(false);
    const pendingRequestsCount = ref("-");
    const rejectedRequestsCount = ref("-");
    const aceptedRequestsCount = ref("-");
    const assignedRequestsCount = ref("-");
    const incompleteRequestCount = ref("-");
    const rangeOfYearRequestWithPayment = ref([]);

    const columns = ref({
      news: [],
      inProgress: [],
      paid: []
    });

    const rows = ref({
      news: [],
      inProgress: [],
      paid: []
    });

    const render = ref({
      news: false,
      inProgress: false,
      paid: false
    });

    let app = getCurrentInstance();
    const affiliatedObject = ref(null);
    const admissionObject = ref(null);
    const router = useRouter();

    const requestWithRegistrationFee = ref({
      news: "-",
      signed: "-",
      unsigned: "-"
    });

    const requestWithRegistrationPaymentSigned = ref({
      news: "-",
      signed: "-",
      unsigned: "-"
    });

    onMounted(async() => {
      isLoading.value = true;
      loadInfo();
      app = app.appContext.config.globalProperties;
      affiliatedObject.value = new AffiliatedRequest();
      admissionObject.value = new AdmissionRequest();
      
      await getPendingRequests();
      await getAceptedRequests();
      await getRejectedRequests();
      await getAssignedRequests();
      await getNewRequests();
      await getIncompleteRequests();
      await getRequestWithRegistrationPayment();
      await getRequestsWithRegistrationFee();
      await getApplicationsWithRegistrationPaymentSigned();
      await getRequestsWithContributionFee();
      await getListContractSavingsSignedDocument();
      isLoading.value = false;
    });
    
    onBeforeMount(() => {
      rangeOfYearRequestWithPayment.value = getRangeOfDatesByYears(7);
    });

    const getRequestsWithRegistrationFee = async () => {
      await admissionObject.value.getRequestsWithRegistrationFee(apiToken.value).then((resp)=>{
        if(!resp.data.response.has_errors){
          const news = resp.data.response.requests.filter(row => row.last_certificate_delivery_date == null);
          const unsigned = resp.data.response.requests.filter(row => row.last_certificate_delivery_date !== null);
          requestWithRegistrationFee.value.news = news.length;
          requestWithRegistrationFee.value.unsigned = unsigned.length;
        }
        isLoading.value = false;
      }).catch(err => {
        console.log("Error", err);
        isLoading.value = false;
      });    
    }
    const getRequestsWithContributionFee = async () => {
      await admissionObject.value.getListContractSavingsDocumentsUnsigned(apiToken.value).then((resp)=>{
        if(!resp.data.response.has_errors){
          const news = resp.data.response.result.filter(row => row.last_contract_send_date == null);
          const unsigned = resp.data.response.result.filter(row => row.last_contract_send_date !== null);
          requestWithRegistrationPaymentSigned.value.news = news.length;
          requestWithRegistrationPaymentSigned.value.unsigned = unsigned.length;
        }
        isLoading.value = false;
      }).catch(err => {
        console.log("Error", err);
        isLoading.value = false;
      });    
    }
    const getApplicationsWithRegistrationPaymentSigned = async () => {
      await admissionObject.value.getApplicationsWithRegistrationPaymentSigned(apiToken.value).then((resp) => {
        requestWithRegistrationFee.value.signed = resp.data.response.requests.length;
      }).catch((err)=>{
        console.log("Error", err);
        isLoading.value = false;
      });
    }
    const getListContractSavingsSignedDocument = async () => {
      await admissionObject.value.getListContractSavingsSignedDocument(apiToken.value).then((resp) => {
        requestWithRegistrationPaymentSigned.value.signed = resp.data.response.result.length;
      }).catch((err)=>{
        console.log("Error", err);
        isLoading.value = false;
      });
    }

    const getColumnsNews = () => {
        columns.value.news = [
          { headerName: "RFC", field: "rfc"},
          { headerName: "Nombre", field: "name" },
          { headerName: "Teléfono", field: "phone" },
          { headerName: "Correo", field: "email", width: 180 },
          { headerName: "Fecha de registro", field: "date", width: 200 },
          { headerName: "Organización", field: "organization" },
          { headerName: "Acciones", field: "id", filter: false, width: 180, cellRenderer: params => {
              const input = document.createElement("label");
              input.innerText = "Asignar";
              input.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1.5 mx-auto rounded-md cursor-pointer text-base";
              input.addEventListener("click", e => {
                e.preventDefault();
                assign(params.value);
              });
              return input;
            }
          }
        ];
    }
    
    const getColumnsInProgress = () => {
        columns.value.inProgress = [
          { headerName: "RFC", field: "rfc"},
          { headerName: "Nombre", field: "name" },
          { headerName: "Teléfono", field: "phone" },
          { headerName: "Correo", field: "email" },
          { headerName: "Fecha de registro", field: "date" },
          { headerName: "Acciones", field: "actions", filter: false, cellRenderer: params => {
              const route = {
                path: "/supervisor/cuenta/revision-solicitudes-ingreso/"+params.value
              };

              const link = document.createElement("a");
              link.href = router.resolve(route).href;
              link.innerText = "Ver";
              link.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1.5 mx-auto rounded-md cursor-pointer text-base";
              link.addEventListener("click", e => {
                e.preventDefault();
                router.push(route);
              });
              return link;
            }
          }
        ];
    }
    const getColumnsPaid = () => {
        columns.value.paid = [
          { headerName: "RFC", field: "rfc"},
          { headerName: "Estatus", field: "status" },
          { headerName: "Fecha de pago", field: "date_enroll_payment_fee" },
          { headerName: "Metodo de pago", field: "method_enroll_payment_fee" },
          { headerName: "Acciones", field: "actions", filter: false, cellRenderer: params => {
              const route = {
                path: "/supervisor/cuenta/revision-solicitudes-ingreso/"+params.value
              };

              const link = document.createElement("a");
              link.href = router.resolve(route).href;
              link.innerText = "Ver";
              link.className += "bg-cherryColor text-white focus:bg-blue-700 px-10 py-1.5 mx-auto rounded-md cursor-pointer text-base";
              link.addEventListener("click", e => {
                e.preventDefault();
                router.push(route);
              });
              return link;
          } },
        ];
    }

    const convertListToArray = (list) => {
      let index = [];
      let array = [];
      for (var i in list) {
        index.push(i);
      }
      for (let i = 0; i < index.length; i++) {
        array.push(list[index[i]]);
      }
      return array;
    }

    const loadInfo = () => {
      var auth = getTokenDecoden();
      apiToken.value = sessionStorage.getItem("login");
      personId.value = auth.obj.personnel_info['id'];
      userId.value = auth['id'];
    }

    const getFinalArrayList = (array) => {
      let finalArray = [];
      if(array == undefined){
        return finalArray;
      }
      for (let i = 0; i < array.length; i++) {
        let contact_info = convertListToArray(array[i].admission_information.contact_info_attributes);
        finalArray.push({
          id: array[i].id,
          rfc: array[i].admission_information.rfc,
          name: app.$filters.formatCamelize(`${array[i].admission_information.name} ${array[i].admission_information.paternal_name}`),
          phone: app.$filters.formatPhone(contact_info.filter(({contact_type}) => contact_type === 'celular').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'celular')[0].value : "1111111111"),
          email: contact_info.filter(({contact_type}) => contact_type === 'correo').length > 0 ? contact_info.filter(({contact_type}) => contact_type === 'correo')[0].value : "correo@gmail.com",
          date : app.$filters.formatDate(array[i].created_at),
          status: array[i].status,
          date_enroll_payment_fee: array[i].admission_information.enrollment_fee_payment_date,
          method_enroll_payment_fee: array[i].admission_information.enrollment_fee_method,
          organization: array[i].person.user.organization_list.join()
        })
      }
      return finalArray;
    }

    const getAceptedRequests = async() => {
      affiliatedObject.value.getNewRequests(apiToken.value, "aceptada" ).then(response => {
        aceptedRequestsCount.value = response.data.response.admission_request.length;
      }).catch(err => {
        console.log('Error', err);
        aceptedRequests.value = [];
        aceptedRequestsCount.value = 0;
      });
    }

    const getRejectedRequests = async() => {
      affiliatedObject.value.getNewRequests(apiToken.value, "rechazada" ).then(response => {
        rejectedRequestsCount.value = response.data.response.admission_request.length;
      }).catch(err => {
        console.log('Error', err);
        aceptedRequests.value = [];
        rejectedRequestsCount.value = 0;
      });
    }

    const getAssignedRequests = async() => {
      assignedRequests.value = [];
      affiliatedObject.value.getNewRequests(apiToken.value, "en_revision" ).then(response => {
        const data = response;
        const dataFind = [];
        data.data.response.admission_request.find(admissionRequest => {
          if(admissionRequest.admission_analyst_id == userId.value)
            dataFind.push(admissionRequest);
        });
       
        assignedRequestsCount.value = dataFind.length;
      }).catch(err => {
        console.log('Error', err);
        assignedRequests.value = [];
        assignedRequestsCount.value = 0;
        isLoading.value = false;
      });

    }

    const getRequests = async() => {
      
      affiliatedObject.value.getPendingRequests(apiToken.value, personId.value ).then(response => {
        let finalArray = getFinalArrayList(response.data.response.admission_request);
        requests.value = finalArray;
    
      }).catch(err => {
        console.log('Error', err);
        aceptedRequests.value = [];
        rejectedRequestsCount.value = 0;
      });
    }

    const getRequestWithRegistrationPayment = async() => {
      rows.value.paid = [];
      render.value.paid = false;
      requestWithRegistrationPayment.value = [];
      admissionObject.value.getListPendingReviewWithPayment(apiToken.value, personId.value ).then(response => {
        requestWithRegistrationPayment.value = [...response.data.response.data.reverse().slice(0, 5)];

        let request = requestWithRegistrationPayment.value.map(value => {
          return {
            id: value.id,
            rfc: value.admission_information.rfc,
            status: app.$filters.requestStatus(value.status),
            created_at: app.$filters.formatDate(value.created_at),
            date_enroll_payment_fee: app.$filters.formatDate(value.date_enroll_payment_fee),
            actions: value.id
          }
        });
        getColumnsPaid();
        rows.value.paid = request;
        render.value.paid = true;
      }).catch(err => {
        console.log('Error', err);
        isLoading.value = false;
        getColumnsPaid();
        requestWithRegistrationPayment.value = [];
      });
    }

    const getNewRequests = async() => {
      isLoading.value = true;
      rows.value.news = [];
      render.value.news = false;
      affiliatedObject.value.getNewRequests(apiToken.value, "pendiente_de_revision" ).then(response => {
        let finalArray = getFinalArrayList(response.data.response.admission_request);
       
        allNewRequests.value = finalArray.reverse();
        newRequests.value = allNewRequests.value.slice(0, 5);
        newRequestsCount.value = allNewRequests.value.length;
        rows.value.news = newRequests.value;
        getColumnsNews();
        render.value.news = true;
        isLoading.value = false;
      }).catch(err => {
        console.log('Error', err);
        isLoading.value = false;
        newRequests.value = [];
        newRequestsCount.value = 0;
      });
    }

    const getPendingRequests = async () => {
      render.value.inProgress = false;
      listTasksIsLoading.value = true;
      affiliatedObject.value.getNewRequests(apiToken.value, "en_revision" ).then(response => {
        let finalArray = getFinalArrayList(response.data.response.admission_request);
        allPendingRequests.value = finalArray.reverse();
        pendingRequests.value = allPendingRequests.value.slice(0, 3);
        pendingRequestsCount.value = allPendingRequests.value.length;
        render.value.inProgress = true;
        isLoading.value = false;
        

        setTimeout(() => {
          listTasksIsLoading.value = false;
        }, 2000)
      }).catch(err => {
        console.log('Error', err);
        isLoading.value = false;
        requestWithRegistrationPayment.value = [];
      });
    }

    const getIncompleteRequests = async() => {
      admissionObject.value.getUnfinishedRequest(apiToken.value).then(response => {
        incompleteRequestCount.value = response.data.response.data.length;
      }).catch(err => {
        console.log('Error', err);
        isLoading.value = false;
      });
    }

    const assign = async(idRequest) => {
      var swal = require('sweetalert2')
      isLoading.value = true;
      admissionObject.value.assingAnalystLogged(apiToken.value, personId.value, idRequest).then(response => {
        getRequests().then((response) => {
          swal.fire({
            title: "Aviso",
            text: "La solicitud fue asignada a un asociado correctamente",
            icon: "success",
            confirmButtonColor: '#FEB72B',
          });
        });
        
        getPendingRequests();
        getAceptedRequests();
        getRejectedRequests();
        getAssignedRequests();
        getNewRequests();
      }).catch(err => {
        isLoading.value = false;
        swal.fire({
          title: "Aviso",
          text: "Ocurrió un error al asignar analista",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
      });

    }

    const redirect = (requestId) => {
      router.push({path: "/supervisor/cuenta/revision-solicitudes-ingreso/"+requestId});
    }

    return {
      columns,
      rows,
      render,
      rangeOfYearRequestWithPayment,
      incompleteRequestCount,
      newRequestsCount,
      isLoading,
      aceptedRequestsCount,
      listTasksIsLoading,
      pendingRequestsCount,
      assignedRequestsCount,
      rejectedRequestsCount,
      requestWithRegistrationFee,
      requestWithRegistrationPaymentSigned
    }
  },
  components: {
    Loading,
    TableComponent,
    ListCard,
  }
}
</script>

<style scoped>
table, th, td {
  border-collapse: collapse;
}
.width200 th, td {
  padding: 15px 10px 15px 10px !important;
}
.requestWithRegistrationPayment, th, td {
  padding: 2px 10px 2px 10px;
}
table, th, td {
  border-bottom: 1px solid #85C875;
}
table thead tr th {
  border-bottom: 1px solid #85C875 !important;
}
tr {
  color: #174A84;
  font-size: 12px;
  font-family:lato;
  
}
#menu ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    font-family: Arial, sans-serif;
    font-size: 11pt;
}
#menu ul {
  width: 100%;
}
#menu ul li {
  font-family: "Lato";
  border-bottom: 1px solid #FFC300;
  color:#810042;
  display: block; 
  padding-bottom: 6px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
}
.buttonFilter {
  border: none;
  border-radius: 5px;
  color: #174A84;
  font-weight:bold;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  widows: 10px;
  width:70px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #dbe9f9;
}
.button {
    border: none !important;
    border-radius: 5px !important;
    color: white !important;
    padding: 1px 32px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-family:lato !important;
    font-size: 13px !important;
    widows: 10px !important;
    cursor: pointer !important;
    background-color: #174A84 !important;
}
input[type="text"] {
    width: 200px;
    height: 20px;
}
/* Sort Table */
.arrow_down {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAYAAABPY4eKAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1PGlEUHQaiiewslpUJiyYs2yb9AyRuJGm7c0VJoFXSX9A0sSZN04ULF12YEBQDhMCuSZOm1FhTiLY2Rky0QPlQBLRUsICoIN/0PCsGyox26NC3eTNn3r3n3TvnvvsE1PkwGo3yUqkkEQqFgw2Mz7lWqwng7ztN06mxsTEv8U0Aam5u7r5EInkplUol/f391wAJCc7nEAgE9Uwmkzo4OPiJMa1Wq6cFs7Ozt0H6RqlUDmJXfPIx+qrX69Ti4mIyHA5r6Wq1egND+j+IyW6QAUoul18XiUTDNHaSyGazKcZtdgk8wqhUKh9o/OMvsVgsfHJy0iWqVrcQNRUMBnd6enqc9MjISAmRP3e73T9al3XnbWNjIw2+KY1Gc3imsNHR0YV4PP5+d3e32h3K316TySQFoX2WyWR2glzIO5fLTSD6IElLNwbqnFpbWyO/96lCoai0cZjN5kfYQAYi5H34fL6cxWIZbya9iJyAhULBHAqFVlMpfsV/fHxMeb3er+Vy+VUzeduzwWC45XA4dlD/vEXvdDrj8DvURsYEWK3WF4FA4JQP9mg0WrHZbEYmnpa0NxYgPVObm5teiLABdTQT8a6vrwdRWhOcHMzMzCiXlpb2/yV6qDttMpkeshEzRk4Wo/bfoe4X9vb2amzGl+HoXNT29vZqsVi0sK1jJScG+Xx+HGkL4Tew2TPi5zUdQQt9otPpuBk3e0TaHmMDh1zS7/f780S0zX6Yni+NnBj09fUZUfvudDrNZN+GkQbl8Xi8RLRtHzsB9Hr9nfn5+SjSeWUCXC7XPq5kw53wsNogjZNohYXL2EljstvtrAL70/mVaW8Y4OidRO1/gwgbUMvcqGmcDc9aPvD1gnTeQ+0nmaInokRj0nHh+uvIiVOtVvt2a2vLv7Ky0tL3cRTXIcpPAwMDpq6R4/JXE4vFQ5FI5CN+QTaRSFCYc8vLy1l0rge4ARe5kJ/d27kYkLXoy2Jo4C7K8CZOsEBvb+9rlUp1xNXPL7v3IDwxvPD6AAAAAElFTkSuQmCC')
}
.arrow_up {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAwpJREFUSA21Vt1PUmEYP4dvkQ8JFMwtBRocWAkDbiqXrUWXzU1rrTt0bdVqXbb1tbW16C9IBUSmm27cODdneoXjputa6069qwuW6IIBIdLvdaF4OAcOiGeDc87zPs/vd57P96WpFq7p6enbGo1mjKZpeTabjU1MTCRagGnOZHFxcXxtbe1XKpUq7+zslJeXl//Mz8+Hy+Uy3RxSE9qTk5M3otFooVQqgef4Wl9f343FYoEmoISrxuNxFX5f9vb2jhn/PxUKhfLS0tIPfFifUESRUMV8Pv/M6XReRm5rTGQyGeXxeGxYe1ezeBpBOBx2rKysbO7v79d4Wy3Y2Nj4GQqFbgnhaugxwiuGJx99Pp9FLBbXxYTXvTqd7v3MzIy6riIWGxJnMpl7AwMD14xGYyMsSq1WUyQdUqn0eSPlusQIsbGrq+vl4OCgvhFQZd1utyv1en0gEolcqsi47nWJlUrlG5fLZVcoFFy2nDKSDpIWlUoVTCQSEk4lCHmJMZ2GTCbTiMVikfIZ88l7enoos9l8dXt7+z6fDicxSJUokqDX6xXcl2wCROoc0vQCWL3sNfLOSdzR0fHY4XC4tVotl40gmVwup9xuN4OQv+UyqCFGH9rg7SOGYVRcBs3IEG4J0nVnamrqOtvuBDGGgQg9+wHFcVEi4a0LNkbdd6TrPKo8ODc311mteIIYjT/a398/jK+s1jnVM0kXoufCFvq0GuiIGEVgQIhfoygM1QrteEa9dAL7ITiYCt4RMabOK5AyKKzKWtvupLcRciu8D5J0EuDDPyT/Snd39yh6VtY2NhYQSR9G79Ds7OxdskRjEyAufvb7/cPoO5Z6e1+xtVKrq6vfcFzyi/A3ZrPZ3GdNSlwgo5ekE4X2RIQGf2C1WlufFE0GBeGWYQ8YERWLxQtnUVB830MKLZfL9RHir8lkssCn2G751tZWEWe03zTKm15YWPiEiXXTYDB0Ig/t7yd8PRws4EicwWHxO4jHD8/C5HiTTqd1BwcHFozKU89origB+y/kmzgYpgOBQP4fGmUiZmJ+WNgAAAAASUVORK5CYII=')
}
.arrow {
  float: right;
  width: 12px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
}
th.active {
  color: #000;
}
th.active .arrow {
  opacity: 1;
}
.arrow {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0px;
  margin-top:7px;
  opacity: 0.66;
}
.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #174A84;
}
.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #174A84;
}
.arrow-right {
  background: #000;
  height: 3px;
  width: 30px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}
#buttonSearch{
  font-family:lato;
}
@media screen and (max-width: 960px) {
  #buttonSearch{
    width: 150px;
  }
}
@media only screen and (max-width: 1024px)  
{
  table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }
  
  .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }
  
  .width200 tr { border: 1px solid #4CAF50; }
  
  .width200 td { border: none;border-bottom: 1px solid #4CAF50; position: relative;padding-left: 40%;text-align:left }
  
  .width200 td:before {  top: 6px; width: 45%; white-space: nowrap;}
  .width200 td:nth-of-type(1):before { content: "R.F.C.:"; color: #174A84; font-weight: bold; }
  .width200 td:nth-of-type(2):before { content: "Nombre:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(3):before { content: "Teléfono:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(4):before { content: "Correo:"; color: #174A84; font-weight: bold;}
  .width200 td:nth-of-type(5):before { content: "Fecha de Registro:"; color: #174A84; font-weight: bold;}
  
  .descarto {display:none;}
  .fontsize {font-size:10px}
}
@media only screen and (max-width: 1024px)  
{
  table.requestWithRegistrationPayment, .requestWithRegistrationPayment thead, .requestWithRegistrationPayment tbody, .requestWithRegistrationPayment th, .requestWithRegistrationPayment td, .requestWithRegistrationPayment tr { display: block; }
  
  .requestWithRegistrationPayment thead tr { position: absolute;top: -9999px;left: -9999px; }
  
  .requestWithRegistrationPayment tr { border: 1px solid #4CAF50; }
  
  .requestWithRegistrationPayment td { border: none;border-bottom: 1px solid #4CAF50; position: relative;padding-left: 40%;text-align:left }
  
  .requestWithRegistrationPayment td:before {  top: 6px; width: 45%; white-space: nowrap;}
  .requestWithRegistrationPayment td:nth-of-type(1):before { content: "R.F.C.:"; color: #174A84; font-weight: bold; }
  .requestWithRegistrationPayment td:nth-of-type(2):before { content: "Estatus:"; color: #174A84; font-weight: bold;}
  .requestWithRegistrationPayment td:nth-of-type(3):before { content: "Fecha de pago:"; color: #174A84; font-weight: bold;}
  .requestWithRegistrationPayment td:nth-of-type(4):before { content: "Metodo de pago:"; color: #174A84; font-weight: bold;}
  
  .descarto {display:none;}
  .fontsize {font-size:10px}
}
</style>