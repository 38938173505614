<template>
    <div :class="classContainer" style="display:flex;align-items:center;background:#fff;border-width: 1px;border-style: solid;border-color:#FFC300;border-radius:7px;border-radius:7px;">
    <div id="menu" style="display:block;width:100%;">
    <ul style="">
        <li style="font-family:lato;font-weight:700;font-size:15px;">
            <div class="grid grid-cols-2">
                <div>
                    {{ title }}
                </div>
                <div>
                    <transition name="rotate">
                            <img 
                            class="w-4"   :style="{ transform: `rotate(${rotation}deg)` }" 
                            src="@/assets/Analyst/setting.svg" alt="setting" style="float:right;">
                    </transition>
                    <div class="text-xs" v-if="showDiv && isLoading">Cargando solicitudes...</div>
                </div>
            </div>
        </li>
        <li v-for="request in listRequests" :key="requestName">
            <div class="grid grid-cols-5">
                <div class="col-span-3">

                    <p @mouseover="showDivLoading" @mouseleave="hideDivLoading" style="float:left;margin-top:5px;">
                        <router-link :to="request.url" style="cursor: pointer;" v-slot="{ navigate }">
                            <a @click.prevent="!isLoading && navigate()">{{request.name}}</a>
                        </router-link>
                    </p>
                </div>
                <div class="grid grid-cols-3 col-span-2">
                    <p class="col-span-1"
                        style="font-family:arial;font-weight:bold;float:left;text-align:right;margin-top:5px;margin-top:5px;">
                        {{ isLoading ? '-' : request.value }}
                    </p>
                    <router-link class="col-span-1" :to="request.url" style="cursor: pointer">
                        <img src="@/assets/Analyst/green-arrow.svg" alt="setting"
                            style="margin-bottom:10px;margin-left:20px;">
                    </router-link>
                </div>
            </div>
        </li>
    </ul>
    </div>
    </div>

</template>
<script>
import { ref, watch } from 'vue';
export default {
    name: "ListCard",
    props: {
        classContainer:{
            type: Object,
            default: () => "row-span-2 col-span-1"
        },
        title: {
            type: String,
            required: true
        },
        listTasksIsLoading: {
            type: Boolean,
            required: true
        },
        listRequests: {
            type: Array,
            required: true,
            default: () => [{
                name: "Solicitudes en proceso",
                value: 12,
                url: "/supervisor/solicitudes-en-proceso"
            },
            {
                name: "Solicitudes Acepatadas",
                value: 3,
                url: "/supervisor/solicitudes-aceptadas"
            }
            ]
        }
    },
    setup(props) {
        const isLoading = ref(props.listTasksIsLoading);
        const showDiv = ref(false);
        const rotation = ref(0);

       let stopInterval = setInterval(function(){
            if(isLoading.value){
                rotation.value += 50
            }
            if(props.listTasksIsLoading == false){
                clearInterval(stopInterval)
            }
        },100);

        watch(() => props.listTasksIsLoading,
            (newValue, oldValue) => {
                isLoading.value = newValue;
            }
        );
            const showDivLoading = () => {
        showDiv.value = true;
        }
        const hideDivLoading = () => {
        showDiv.value = false;
        }

        return {
            showDiv,
            isLoading,
            rotation,
            showDivLoading,
            hideDivLoading
        }
    }
}
</script>

<style scoped>

#menu ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    font-family: Arial, sans-serif;
    font-size: 11pt;
}
#menu ul {
  width: 100%;
}
#menu ul li {
  font-family: "Lato";
  border-bottom: 1px solid #FFC300;
  color:#810042;
  display: block; 
  padding-bottom: 6px;
  padding-top: 9px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>

